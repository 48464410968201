import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { ThemeContext } from "src/providers/ThemeProvider"
import { Container } from "src/components/common"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Wrapper, StackWrapper, Item, Head, Headline } from "./styles"

export const Customers = () => {
  const { theme } = useContext(ThemeContext)
  const { t } = useTranslation()

  const data = useStaticQuery(graphql`
    query {
      images: allFile(filter: { relativeDirectory: { eq: "customers" } } , sort: { fields: name } ) {
        edges {
          node {
            name
            publicURL
            childImageSharp {
              fixed(width: 60) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
  `)

  // data.images.edges.map((item, index) => (
  //   console.log(index," - ",item.node)
  // ));

  return (
    <Wrapper id="customers">
      <Head as={Container}>
        <Headline>{t("customers")}</Headline>
      </Head>

      <StackWrapper>

      {data.images.edges.map((item, index) => {
          return item.node.extension === "svg" || !item.node.childImageSharp ? <Item key={index} theme={theme}>
            <img alt={item.node.name} src={item.node.publicURL} /> 
          </Item>:<Item key={index} theme={theme}>
          <Img alt={item.node.name} fixed={item.node.childImageSharp.fixed} />
          </Item>
        })}
      </StackWrapper>
    </Wrapper>
  )
}
