import styled from "styled-components"
// import detailsIllustration from 'assets/illustrations/details.svg';

export const Wrapper = styled.div`
margin: 2rem 0;

  @media (min-width: 992px) {
    margin: 1rem 0;
  }
`

// export const Wrapper = styled.div`
//   background-image: url(${detailsIllustration});
//   background-size: contain;
//   background-position: left top;
//   background-repeat: no-repeat;
// `;

export const SkillsWrapper = styled.div``

export const LeftBox = styled.div`
  padding: 1.5rem;
  flex-direction: center;
  margin: 2rem 0;

  ${({ background }) =>
    background &&
    `
		background: ${background};
  `}
  @media (min-width: 576px) {
    margin: 2rem 0;
    padding: 2rem;
  }
  @media (min-width: 992px) {
    margin: 3rem 0;
    width: calc(100% - 200px);
    margin-right: 200px;
    padding: 3rem;
  }

  @media (max-width: 575px) {
    transition: none !important;

  }
`

export const RightBox = styled.div`
  padding: 1.5rem;
  flex-direction: center;
  margin: 2rem 0;
  ${({ background }) =>
    background &&
    `
		background: ${background};
  `}

  @media (min-width: 576px) {
    margin: 2rem 0;
    padding: 2rem;
  }

  @media (min-width: 992px) {
    margin: 6rem 0;
    width: calc(100% - 200px);
    margin-left: 200px;
    padding: 3rem;
  }
  @media (max-width: 575px) {
    transition: none !important;

  }
`

export const BoxInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 576px) {
    flex-direction: row;
  }
  h3 {
    font-family: "raleway-bold";
    font-size: 1.6rem;
    margin: 30px 0 15px 0;
  }
  p {
    font-family: "raleway-regular";
    font-size: 0.85rem;
    line-height: 1.5rem;
  }
  > div {
    &:first-child {
      @media (min-width: 576px) {
        width: 60%;
      }
    }
    &:last-child {
      display: flex;
      align-items: center;
      @media (min-width: 576px) {
        width: 40%;
        padding-left: 5%;
      }
    }

    ${({ align }) =>
      align === "right" &&
      `
    &:first-child {
      order: 3;
      
      @media (min-width: 576px) {
        display: flex;
        align-items: center;
        width: 40%;
        padding-right: 5%;
        order: 0;
      }
    }
    &:last-child {
      display: block;
      @media (min-width: 576px) {
        width: 60%;
      }
    }
  `}
    > img {
      max-height: 200px;
      width: 100%;
      @media (min-width: 576px) {
        max-height: 300px;
      }
    }
  }
  @media (min-width: 992px) {
  }
`

export const More = styled.div`
  width: 100%;
`

export const Details = styled.div`
  flex: 1;
  padding-left: 2rem;

  @media (min-width: 992px) {
    padding-left: unset;
    width: 100%;
  }

  h1 {
    margin-bottom: 2rem;
    font-size: 26pt;
    color: ${({ theme }) => (theme === "dark" ? "#fff" : "#212121")};

    @media (min-width: 992px) {
      mix-blend-mode: ${({ theme }) =>
        theme === "light" ? "unset" : "difference"};
    }
  }

  p {
    margin-bottom: 2.5rem;
    font-size: 20pt;
    font-weight: normal;
    line-height: 1.3;
    color: ${({ theme }) => (theme === "dark" ? "#c7c7c7" : "#707070")};

    @media (min-width: 992px) {
      mix-blend-mode: ${({ theme }) =>
        theme === "light" ? "unset" : "difference"};
    }
  }
`
