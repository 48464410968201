
import React, { useContext } from 'react';
import { ThemeContext } from 'src/providers/ThemeProvider';
import { Container} from 'src/components/common';

import { useTranslation} from 'gatsby-plugin-react-i18next';

import { Wrapper,ContactWrapper, Email, Headline  } from './styles';


export const Contact = () => {
  const {t} = useTranslation();
  const { theme } = useContext(ThemeContext);
  return (
    <Wrapper id="contact"  theme={theme}>
      <ContactWrapper as={Container}>
        <Headline theme={theme}>{t('contact:headline')}</Headline>
        <Email theme={theme} href="mailto:&#097;&#110;&#102;&#114;&#097;&#103;&#101;&#064;&#109;&#101;&#100;&#105;&#101;&#110;&#107;&#114;&#097;&#109;&#046;&#100;&#101;">{t('contact:email')}</Email>
        
      </ContactWrapper>
   </Wrapper>
  );
}; 