import styled from "styled-components"

export const Wrapper = styled.div`
  margin: 2rem 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (min-width: 992px) {
    margin: 3rem 0;
  } 
`

export const ProjectsWrapper = styled.div`
  width: 100%;

  div {
    &:focus {
      outline: none;
    }
  }
`

export const Head = styled.div`
  display: flex;
  
  margin: 1rem 0 0 0;
  flex-direction:column;
  @media (min-width: 576px) {
    flex-direction: row;
    justify-content: space-between;
  }
  @media (min-width: 992px) {
    margin: 3rem 0 3rem 0;
  }
`

export const Headline = styled.div`
  font-size: 2.5rem;
  font-weight: 600;
  text-align: center;
  margin: 0 0 1rem 0;
  @media (min-width: 576px) {
    font-size: 3rem;
    font-weight: 100;
    text-align: left;
  }
`
export const Slide = styled.div`
  display: flex;
  flex-direction: column-reverse;
  @media (min-width: 992px) {
    flex-direction: row;
  }
  &:focus {
    outline: none;
  }
`

export const MoreLink = styled.a`
  transition: border 350ms ease 0s, opacity 0.5s ease 0.8s !important;
  color: var(--dark-1);
  color: ${({ theme }) =>
  theme === "light" ? "var(--dark-1)" : "white"};

  cursor: pointer;
  font-family: "raleway-bold";
  font-size: 0.85rem;
  margin: 30px 10px 0 0;
  border: 2px solid transparent;
  display: inline-block;

  @media (min-width: 576px) {
  }

  &:hover {
    border-bottom-color: var(--dark-1);
  }
`

export const ProjectText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  border-radius: 26px;
  padding: 2rem;
  background: var(--gray-1);

  background: ${({ theme }) =>
    theme === "light" ? "var(--gray-1)" : "var(--blue-dark)"};
  width: 100%;
  @media (min-width: 992px) {
    width: 50%;
    padding: 4rem;
    min-height: 400px;
  }

  h4 {
    font-family: "raleway-bold";
    font-size: 1.2rem;
    margin: 0 0 15px 0;
    @media (min-width: 576px) {
      font-size: 1.6rem;
    }
  }
  p {
    font-family: "raleway-regular";
    font-size: 0.85rem;
    line-height: 1.5rem;
  }
`

export const ProjectImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 400px;
  @media (min-width: 992px) {
    width: 50%;
    min-height: none;
  }
`

export const Navigation = styled.div`
  width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

export const Button = styled.div`
  transition: all 0.3s ease;
  width: 40px;
  height: 40px;
  background: var(--gray-1);
  margin: 0 15px;
  border-radius: 6px;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    transition: all 0.3 ease;
    opacity: 0.5;
  }
  &:hover {
    img {
      opacity: 1;
    }
  }
`
