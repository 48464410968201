import React, { useContext } from "react"
import { ThemeContext } from "src/providers/ThemeProvider"
import {
  Container,
  Box,
  ReadMore,
  RoundedIcon,
} from "src/components/common"
import {
  Wrapper,
  SkillsWrapper,
  RightBox,
  LeftBox,
  BoxInner,
  More,
} from "./styles"

import { useTranslation } from "gatsby-plugin-react-i18next"
import konzeptionIcon from "src/assets/icons/review.svg"
import webIcon from "src/assets/icons/browser.svg"
import mobileIcon from "src/assets/icons/application.svg"

import konzeptionIllustration from "src/assets/illustrations/analytics_process_monochromatic.svg"
import webIllustration from "src/assets/illustrations/coding.svg"
import mobileIllustration from "src/assets/illustrations/mobile_phone.svg"
import reqIcon from "src/assets/icons/req.svg"
import architectureIcon from "src/assets/icons/architecture.svg"
import peopleIcon from "src/assets/icons/people.svg"
import timeIcon from "src/assets/icons/time.svg"
import phoneIcon from "src/assets/icons/phone.svg"
import speedIcon from "src/assets/icons/speed.svg"
import designIcon from "src/assets/icons/design.svg"
import speechIcon from "src/assets/icons/speech.svg"
import computerIcon from "src/assets/icons/computer.svg"
import agileIcon from "src/assets/icons/agile.svg"
import settingsIcon from "src/assets/icons/settings.svg"

const icons = {
  reqIcon,
  architectureIcon,
  peopleIcon,
  timeIcon,
  phoneIcon,
  speedIcon,
  designIcon,
  speechIcon,
  computerIcon,
  agileIcon,
  settingsIcon,
}

export const Skills = () => {
  const { theme } = useContext(ThemeContext)
  const { t } = useTranslation()
  return (
    <Wrapper id="skills">
      <SkillsWrapper as={Container}>
        <LeftBox background={theme === "light" ? "#fff6e8" : "#9c6918"} as={Box} data-sal="slide-up" data-sal-duration="500" data-sal-delay="100" data-sal-easing="ease">
          <BoxInner>
            <div data-sal="slide-up" data-sal-duration="500" data-sal-delay="500">
              <RoundedIcon background="#ffc569">
                <img src={konzeptionIcon} alt={t("skills:hl_konzeption")} />
              </RoundedIcon>
              <h3>{t("skills:hl_konzeption")}</h3>
              <p>{t("skills:tx_konzeption")}</p>
            </div>
            <div data-sal="slide-up" data-sal-duration="500" data-sal-delay="500">
              <img
                src={konzeptionIllustration}
                alt={`Illustration `+t("skills:hl_konzeption")}
              />
            </div>
          </BoxInner>
          <More>
            <ReadMore>
              {t("skills:more_konzeption", { returnObjects: true }).map(
                (item,index) => (
                  <div key={index}>
                    <RoundedIcon small="true" background="#ffc569">
                      <img src={icons[item.icon]} alt={item.title} />
                    </RoundedIcon>
                    <h4>{item.title}</h4>
                    <p>{item.content}</p>
                  </div>
                )
              )}
            </ReadMore>
          </More>
        </LeftBox>

        <RightBox background={theme === "light" ? "#dff2ed" : "#037557"} as={Box} data-sal="slide-up" data-sal-duration="500" data-sal-delay="100" data-sal-easing="ease">
          <BoxInner align="right">
            <div  data-sal="slide-up" data-sal-duration="500" data-sal-delay="500">
              <img src={webIllustration} alt={`Illustration `+t("skills:hl_konzeption")} />
            </div>
            <div data-sal="slide-up" data-sal-duration="500" data-sal-delay="500">
              <RoundedIcon background="#b1f2e1">
                <img src={webIcon} alt={t("skills:hl_web")} />
              </RoundedIcon>
              <h3>{t("skills:hl_web")}</h3>
              <p>{t("skills:tx_web")}</p>
            </div>
          </BoxInner>
          <More>
            <ReadMore align="right">
              {t("skills:more_web", { returnObjects: true }).map((item,index) => (
                <div key={index}>
                  <RoundedIcon small="true" background="#b1f2e1">
                    <img src={icons[item.icon]} alt={item.title} />
                  </RoundedIcon>
                  <h4>{item.title}</h4>
                  <p>{item.content}</p>
                </div>
              ))}
            </ReadMore>
          </More>
        </RightBox>

        <LeftBox background={theme === "light" ? "#f0f6ff" : "#12376f"} as={Box} data-sal="slide-up" data-sal-duration="500" data-sal-delay="100" data-sal-easing="ease">
          <BoxInner>
            <div data-sal="slide-up" data-sal-duration="500" data-sal-delay="500">
              <RoundedIcon background="#a1c6ff">
                <img src={mobileIcon} alt={t("skills:hl_mobile")} />
              </RoundedIcon>
              <h3>{t("skills:hl_mobile")}</h3>
              <p>{t("skills:tx_mobile")}</p>
            </div>
            <div data-sal="slide-up" data-sal-duration="500" data-sal-delay="500">
              <img src={mobileIllustration} alt={`Illustration `+t("skills:hl_mobile")} />
            </div>
          </BoxInner>
          <More>
            <ReadMore>
              {t("skills:more_mobile", { returnObjects: true }).map((item,index) => (
                <div key={index}>
                  <RoundedIcon small="true" background="#a1c6ff">
                    <img src={icons[item.icon]} alt={item.title} />
                  </RoundedIcon>
                  <h4>{item.title}</h4>
                  <p>{item.content}</p>
                </div>
              ))}
            </ReadMore>
          </More>
        </LeftBox>

      </SkillsWrapper>
    </Wrapper>
  )
}
