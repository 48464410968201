import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  background: ${({ theme }) =>
    theme === "light" ? "var(--blue-light)" : "var(--blue-dark)"};
     

  @media (min-width: 992px) {
    min-height: 500px;
  }
  @media (min-width: 1200px) {
    min-height: 700px; 
  } 
`

export const ContactWrapper = styled.div`
  padding: 3.5rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (min-width: 992px) {
    padding: 4rem 0;
  }
`

export const Headline = styled.h4`
  font-size: 1.5rem;
  color: ${({ theme }) =>
    theme === "light" ? "var(--blue-dark)" : "var(--blue-light)"};
    text-align: center;
  font-weight: 300;
  margin: 0 0 3rem 0;
  font-family: "raleway-semibold";
`

export const Location = styled.div`
  font-size: 1.2rem;
  color: ${({ theme }) =>
    theme === "light" ? "var(--blue-dark)" : "var(--blue-light)"};

  font-family: "raleway-light";
  @media (min-width: 992px) {
    font-size: 1.5rem;
  }
`

export const Email = styled.a`
  transition: border 350ms ease 0s, opacity 0.5s ease 0.8s !important;
  font-size: 1.5rem;
  color: ${({ theme }) =>
    theme === "light" ? "var(--blue-dark)" : "var(--blue-light)"};

  font-family: "raleway-semibold";
  margin: 1rem 0;

  border: 2px solid transparent;
  display: inline-block;
  &:hover {
    border-bottom-color: ${({ theme }) =>
    theme === "light" ? "var(--blue-dark)" : "var(--blue-light)"};
  }
  @media (min-width: 992px) {
    font-size: 2.4rem;
  }
`

export const Phone = styled.a`
  transition: border 350ms ease 0s, opacity 0.5s ease 0.8s !important;
  font-size: 1.2rem;
  color: ${({ theme }) =>
    theme === "light" ? "var(--blue-dark)" : "var(--blue-light)"};

  font-family: "raleway-light";
  border: 1px solid transparent;
  display: inline-block;
  &:hover {
    
    border-bottom-color: ${({ theme }) =>
    theme === "light" ? "var(--blue-dark)" : "var(--blue-light)"};
  }
  @media (min-width: 992px) {
    font-size: 1.5rem;
  }
`
