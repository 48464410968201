import styled from "styled-components"

export const Wrapper = styled.div`
  margin: 3rem 0;
  @media (min-width: 992px) {
    margin: 6rem 0;
  }
`

export const Head = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: 1rem;
  margin-bottom: 2rem;
  @media (min-width: 992px) {
    margin-top: 4rem;
    margin-bottom: 2rem;
  }
`

export const Headline = styled.div`
  font-size: 2.25rem;
  font-weight: 600;
  text-align: center;
  margin: 0 0 1rem 0;
  @media (min-width: 576px) {
    font-size: 3rem;
    font-weight: 100;
    text-align: left;
  }
`

export const StackWrapper = styled.ul`
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
  max-width: 1280px;
  margin: 0 auto;
`

export const Item = styled.li`
  transition: all 0.3s ease;
  width: 120px;
  height: 120px;
  border-radius: 12px;

  background: ${({ theme }) =>
    theme === "light" ? "var(--gray-1)" : "var(--gray-1)"};
  margin: 0.5rem;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 992px) {
    width: 180px;
    height: 180px;
    margin: 1rem;
  }
  img {
    max-width: 90px !important;
    max-height: 60px !important;

    @media (min-width: 992px) {
      max-width: 150px !important;
      max-height: 90px !important;
    }
  }
  picture {
    img {
      transition: all 300ms ease !important;
      width: 90% !important;
      height: 90% !important;
      left: 5% !important;
      top: 5% !important;
    }
  }
  &:hover {
    border-radius: 4px;
    padding: 0;
    picture {
      img {
        width: 100% !important;
        height: 100% !important;
        left: 0% !important;
        top: 0% !important;
      }
    }
  }
`
