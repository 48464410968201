import styled from "styled-components"

export const Wrapper = styled.div``

export const IntroWrapper = styled.div`
  margin-top: 6rem;
  margin-bottom: 2rem;
  text-align: center;
  display: flex;
  align-items: center;
  // justify-content: center
  flex-direction: column;
  @media (min-width: 992px) {
    margin-top: 10rem;
  margin-bottom: 2rem;
  }
  
  img {
    width: 100%;
    height: auto;
    max-width: 100px;
  }
`

export const Details = styled.div`

  max-width: 920px; 
  @media (min-width: 992px) {
    width: 100%;
  }
  h2 {
    font-size: 2rem;
    font-family: "raleway-bold";
    @media (min-width: 576px) {
      font-size: 2.5rem;
    }

    @media (min-width: 992px) {
      mix-blend-mode: ${({ theme }) =>
        theme === "light" ? "unset" : "difference"};
    }
  }
  p {
    font-size: 1.25rem;
    line-height: 2rem;
    @media (min-width: 576px) {
      font-size: 1.4rem;
    line-height: 2.2rem;
    }
    @media (max-width: 992px) {
      mix-blend-mode: ${({ theme }) =>
        theme === "light" ? "unset" : "difference"};
    }
  }
`

export const Avatar = styled.div`
  max-width: 250px;
 
 

  @media (min-width: 992px) {
    width: 100%;
  }
  img {
    width: 100%;
    border-radius: 12px;
  }
`
