import React from "react"
import { Layout, SEO } from "src/components/common"
import {
  Intro,
  Skills,
  Contact,
  Claim,
  Projects,
  Stack,
  Customers,
  Quotes
} from "src/components/landing"

const IndexPage = () => (
  <Layout>
    <SEO />
    <Intro />
    <Claim />
    <Skills />
    <Customers/>
    <Projects />
    <Stack />
    <Quotes />
    <Contact />
  </Layout>
)

export default IndexPage
