import React, { useRef,useContext } from "react"
import { ThemeContext } from 'src/providers/ThemeProvider';
import { Container, ReferenceImage } from "src/components/common"
import { useTranslation } from "gatsby-plugin-react-i18next"
import {
  Wrapper,
  ProjectsWrapper,
  Head,
  Headline,
  ProjectText,
  ProjectImage,
  Slide,
  Button,
  Navigation,
  MoreLink,
} from "./styles"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import arrowRight from "src/assets/icons/arrow-right.svg"
import arrowLeft from "src/assets/icons/arrow-left.svg"
export const Projects = () => {
  const { t } = useTranslation()
  const customSlider = useRef()
  const { theme } = useContext(ThemeContext);
  const SliderSettings = {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: false,
    autoplay: false,
    autoplaySpeed: 5000,
    lazyLoad: true,
    arrows: false
  }

  return (
    <Wrapper id="references">
      <Head as={Container}>
        <Headline>{t("projects:headline")}</Headline>
        <Navigation>
          <Button onClick={() => customSlider.current.slickPrev()}>
            <img src={arrowLeft} alt={"Prev"} />
          </Button>
          <Button onClick={() => customSlider.current.slickNext()}>
            <img src={arrowRight} alt={"Next"} />
          </Button>
        </Navigation>
      </Head>
      <ProjectsWrapper as={Container}>
        <Slider ref={customSlider} {...SliderSettings}>
          {t("projects:items", { returnObjects: true }).map((item, index) => (
            <div key={index}>
              <Slide>
                <ProjectText  theme={theme}>
                  <h4 dangerouslySetInnerHTML={{ __html: item.title }} />
                  <div dangerouslySetInnerHTML={{ __html: item.text }} />

                  <small dangerouslySetInnerHTML={{ __html: item.legend }} />
                  {item.links && (
                    <div>
                      {item.links.map((itemLink, indexLink) => (
                        <MoreLink
                        theme={theme}
                          key={indexLink}
                          target="_blank"
                          rel="noopener"
                          href={itemLink.url}
                        >
                          {itemLink.title}
                        </MoreLink>
                      ))}
                    </div>
                  )}
                </ProjectText>
                <ProjectImage>
                  <ReferenceImage
                    alt={item.title}
                    filename={item.image}
                    shape={item.shape}
                    color={item.color}
                    width={item.shapeWidth}
                    height={item.shapeHeight}
                  />
                </ProjectImage>
              </Slide>
            </div>
          ))}
        </Slider>
      </ProjectsWrapper>
    </Wrapper>
  )
}
