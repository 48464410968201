import React, { useContext } from "react"
import { ThemeContext } from "src/providers/ThemeProvider"
import { Container } from "src/components/common"
import { useTranslation } from "gatsby-plugin-react-i18next"
// import dev from 'src/assets/images/gatsby-astronaut.png';

import { Wrapper, IntroWrapper, Details } from "./styles"


export const Claim = () => {
  const { theme } = useContext(ThemeContext)
  const { t } = useTranslation()

  return (
    <Wrapper theme={theme}  id="focus">
      <IntroWrapper as={Container}>
   
        <Details theme={theme}>
          <h2>{t("claim")}</h2>
          <p>{t("description")}</p>
        </Details>
      </IntroWrapper>
    </Wrapper>
  )
}
