import styled from "styled-components"

export const Wrapper = styled.div`
  margin: 2rem 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  
  @media (min-width: 992px) {
    margin: 3rem 0;
  } 
`

export const QuotesWrapper = styled.div`
  width: 100%;
  // padding: 0;
  @media (min-width: 576px) {
   
  }
  div {
    &:focus {
      outline: none;
    }
  }
`

export const Head = styled.div`
  display: flex;
 
  margin: 1rem 0 0 0;
  flex-direction:column;
  @media (min-width: 576px) {
    flex-direction: row;
    justify-content: space-between;
  }
  @media (min-width: 992px) {
    margin: 3rem 0 3rem 0;
  }
`

export const Headline = styled.div`
  font-size: 2.25rem;
  font-weight: 600;
  text-align: center;
  margin: 0 0 1rem 0;
  @media (min-width: 576px) {
    font-size: 3rem;
    font-weight: 100;
    text-align: left;
  }
`
export const Slide = styled.div`
  display: flex;
  flex-direction: column-reverse;
  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: center;
  }
  &:focus {
    outline: none;
  }
`

export const QuoteText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  border-radius: 40px;
  padding: 2rem;
  margin: 15px 0; 
  background: var(--gray-1);

  background: ${({ theme }) =>
    theme === "light" ? "var(--gray-1)" : "var(--blue-dark)"};
  width: calc(100% - 0);
  position: relative;

  @media (min-width: 576px) {
    width: calc(100% - 2rem);
    margin: 15px 10px; 
  }
  @media (min-width: 992px) {
    width: 60%;
    padding: 4rem;
    min-height: 400px;
    border-radius: 80px;
    margin: 50px;
  }

  h4 {
    font-family: "raleway-bold";
    font-size: 1rem;
    line-height: 1.25rem;
    margin: 0 0 25px 0;
    @media (min-width: 576px) {
      font-size: 1.25rem;
    line-height: 1.75rem;
    }
    @media (min-width: 992px) {
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }
  p {
    font-family: "raleway-regular";
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin: 0 0 10px 0;
  }
  small {
    font-family: "raleway-regular";
    font-size: 0.85rem;
    line-height: 1.5rem;
    margin: 0;
  }
`

export const MarkerIcon = styled.div`
  position: absolute;
  right: 10px; 
  top: -15px;
  width: 50px;
  height: 50px;

  @media (min-width: 992px) {
    right: -30px;
    bottom: -30px;
    top: auto;
    width: 100px;
    height: 100px;
  }
  > img {
    width: 100%;
    height: 100%;
  }
`
 
export const Navigation = styled.div`
  width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

export const Button = styled.div`
  transition: all 0.3s ease;
  width: 40px;
  height: 40px;
  background: var(--gray-1);
  margin: 0 15px;
  border-radius: 6px;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    transition: all 0.3 ease;
    opacity: 0.5;
  }
  &:hover {
    img {
      opacity: 1;
    }
  }
`
