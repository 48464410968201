import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import AnchorLink from "react-anchor-link-smooth-scroll"
import { ThemeContext } from "src/providers/ThemeProvider"
import { Header } from "src/components/theme"
import { Container, Button } from "src/components/common"

import { useTranslation } from "gatsby-plugin-react-i18next"

// import dev from 'src/assets/images/gatsby-astronaut.png';

import {
  Wrapper,
  IntroWrapper,
  Details,
  Avatar,
  BackgroundShape,
  ListTitle,
  List
} from "./styles"

export const Intro = () => {
  const { theme } = useContext(ThemeContext)
  const { t } = useTranslation()

  const data = useStaticQuery(graphql`
    query {
      potrait: file(relativePath: { eq: "potrait-stefanhelker-shape.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Wrapper theme={theme}>
      <Header />
      <IntroWrapper as={Container}>
        <Avatar>
          <Img fluid={data.potrait.childImageSharp.fluid} />
          <BackgroundShape />
        </Avatar>
        <Details theme={theme}>
          <h2>{t("header:name")}</h2>
          <h1>{t("header:title")}</h1>
          <ListTitle theme={theme}>{t("header:list_title")}</ListTitle>
          <List theme={theme}>
            {t("header:items", { returnObjects: true }).map((item, index) => (
              <li key={index}>
                <p dangerouslySetInnerHTML={{ __html: item.title }} />
              </li>
            ))}
          </List>

          <Button as={AnchorLink} href="#focus">
            {t("focus")}
          </Button>

          <Button as={AnchorLink} href="#references">
            {t("references")}
          </Button>

          <Button as={AnchorLink} href="#contact">
            {t("contactBtn")}
          </Button>
        </Details>
      </IntroWrapper>
    </Wrapper>
  )
}
