import styled from "styled-components"
// import overlayIllustration from 'assets/illustrations/overlay.svg';

// export const Wrapper = styled.div`
//   padding-bottom: 4rem;
//   background-image: url(${overlayIllustration});
//   background-size: contain;
//   background-position: right top;
//   background-repeat: no-repeat;
// `;
export const Wrapper = styled.div`
  padding-top: 20px;
  background: ${({ theme }) =>
    theme === "light" ? "var(--blue-light)" : "var(--blue-dark)"};
`
// background: ${({ theme }) => theme === "light" ? "var(--blue-base)" : "var(--blue-dark)"};
export const IntroWrapper = styled.div`
  min-height: 600px;
  padding: 0rem 1.5rem 4rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (min-width: 992px) {
    align-items: center;
    padding: 4rem 0;
    flex-direction: row;
  }
  @media (min-width: 1200px) {
    min-height: 700px;
  }
`

export const Details = styled.div`
  @media (min-width: 576px) {
    max-width: 480px;
  }
  @media (min-width: 768px) {
    max-width: 500px;
  }
  @media (min-width: 992px) {
    width: 100%;
    margin-left: 100px;
    max-width: 800px;
  }
  h1 {
    font-size: 2rem;
    font-family: "raleway-semibold";

    color: ${({ theme }) =>
      theme === "light" ? "var(--blue-dark)" : "var(--blue-light)"};

    margin-bottom: 1rem;
    @media (min-width: 576px) {
    }
    @media (min-width: 768px) {
      font-size: 2.5rem;
    }

    @media (min-width: 992px) {
      font-size: 3rem;
    }

    @media (min-width: 1200px) {
      font-size: 3.5rem;
    }
  }
  h2 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: ${({ theme }) =>
      theme === "light" ? "var(--blue-dark)" : "var(--blue-light)"};

    @media (min-width: 576px) {
      font-size: 2rem;
    }
    @media (min-width: 992px) {
    }
    @media (min-width: 1200px) {
      font-size: 3rem;
    }
  }
  a {
    display: block;
    width: auto;

    color: var(--blue-dark);
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 0;
    }

    @media (min-width: 576px) {
      display: inline-block;
      margin-left: 15px;
      &:first-of-type {
        margin-left: 0;
        margin-bottom: 0;
      }
    }
  }
`

export const ListTitle = styled.p`
  font-size: 1.25rem;
  font-family: "raleway-semibold";

  color: ${({ theme }) =>
    theme === "light" ? "var(--blue-dark)" : "var(--blue-light)"};

  margin: 0rem 0 1rem 0;
`

export const List = styled.ul`
  margin: 0 0 3rem 0;
  color: ${({ theme }) =>
    theme === "light" ? "var(--blue-dark)" : "var(--blue-light)"};
  padding: 0 0 0 1.5rem;
  list-style: none;
  > li {
    position: relative;
    &:after {
      position: absolute;
      left: -1.5rem;
      top: calc(50% - 8px);
      width: 1rem;
      height: 1rem;
      content: "";
      background: var(--blue-base-2);
      opacity: 0.4 ;
      border-radius: 50%;
    }
    &:before {
      /* border: 1px dotted red; */
      position: absolute;
      left: -1.5rem;
      top: calc(50% - 8px);
      width: 1rem;
      height: 1rem;
      content: "";
      display: block;
      z-index: 10;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 60%;
      background-image: url('data:image/svg+xml;charset=utf8, <svg style="enable-background:new 0 0 50 50;" version="1.1" viewBox="0 0 50 50" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Layer_1"><polygon points="26,1 24,1 24,24 1,24 1,26 24,26 24,49 26,49 26,26 49,26 49,24 26,24  " fill="%230e3b6b"/></g><g/></svg>');
    }
  }
`

export const Avatar = styled.div`
  position: relative;
  max-width: 250px;
  width: 100%;
  margin-bottom: 40px;
  @media (min-width: 576px) {
  }
  @media (min-width: 992px) {
    max-width: 450px;
    width: 100%;
    margin-bottom: 0px;
  }
  .gatsby-image-wrapper {
    z-index: 3;
  }
  img {
    width: 100%;
    border-radius: 12px;
  }
`

export const BackgroundShape = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 0;
  &:after {
    // display: block;
    // content: "";
    // background: #519ef5;
    // width: 450px;
    // height: 350px;
    // border-radius: 40% 60% 35% 65% / 61% 73% 27% 39%;
    // position: absolute;
    // top: 80%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // z-index: 0;
  }
`
