import React, { useRef,useContext } from "react"
import { ThemeContext } from 'src/providers/ThemeProvider';
import { Container } from "src/components/common"
import { useTranslation } from "gatsby-plugin-react-i18next"
import {
  Wrapper,
  QuotesWrapper,
  Head,
  Headline,
  QuoteText,
  MarkerIcon,
  Slide,
  Button,
  Navigation,
} from "./styles"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import arrowRight from "src/assets/icons/arrow-right.svg"
import arrowLeft from "src/assets/icons/arrow-left.svg"
import quoteMarker from "src/assets/icons/quote-marker-blue-dark.svg"
export const Quotes = () => {
  const { t } = useTranslation()
  const customSlider = useRef()
  const { theme } = useContext(ThemeContext);
  const SliderSettings = {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: false,
    autoplay: false,
    autoplaySpeed: 5000,
    lazyLoad: true,
    arrows: false
  }

  return (
    <Wrapper id="quotes">
      <Head as={Container}>
        <Headline>{t("quotes:headline")}</Headline>
        <Navigation>
          <Button onClick={() => customSlider.current.slickPrev()}>
            <img src={arrowLeft} alt={"Prev"} />
          </Button>
          <Button onClick={() => customSlider.current.slickNext()}>
            <img src={arrowRight} alt={"Next"} />
          </Button>
        </Navigation>
      </Head>
      <QuotesWrapper as={Container}>
        <Slider ref={customSlider} {...SliderSettings}>
          {t("quotes:items", { returnObjects: true }).map((item, index) => (
            <div key={index}>
              <Slide>
                <QuoteText  theme={theme}>
                  <h4 dangerouslySetInnerHTML={{ __html: item.quote }} />
                  <p dangerouslySetInnerHTML={{ __html: item.name }} />
                  <small dangerouslySetInnerHTML={{ __html: item.subtitle }} />
                  <MarkerIcon theme={theme}>
                    <img src={quoteMarker} alt={"quote"} />
                  </MarkerIcon>
                </QuoteText>
              </Slide>
            </div>
          ))}
        </Slider>
      </QuotesWrapper>
    </Wrapper>
  )
}
